<template>
  <div class="purchases">
    <div class="top-bar">
      <button class="arrow-button" @click="dateDecr()">&#9664;</button>
      <p class="date">{{ date }}</p>
      <button class="arrow-button" @click="dateIncr()">&#9654;</button>
    </div>
    <div v-if="purchases">
      <h2>Proceeds: {{ Math.floor(totalProceeds) }} kr</h2>
      <div class="product-counts">
        <p class="product-count">{{pass10Count}}</p>
        <p class="product-count-label">10-day</p>
        |
        <p class="product-count">{{subMonthCount}}</p>
        <p class="product-count-label">Monthly</p>
        |
        <p class="product-count">{{subYearCount}}</p>
        <p class="product-count-label">Yearly</p>
      </div>
    </div>
    <div v-else>Loading data...</div>
    <div class="vertical-container">
      <MultipleChoiceToggle class="platform-toggle" :options="platformLabels" :onSelected="onPlatformSelected"/> 
      <MultipleChoiceToggle class="timeframe-toggle" :options="timeframeLabels" :onSelected="onTimeframeSelected"/> 
    </div>
    <div class="horizontal-container">
      <div class="chart">
        <p class="chart-title">Purchases</p>
        <TimelineChart ref="purchasesChart" :interval="timeframe" :labels="purchaseTypes" :endDate="endDate" :colors="purchaseColors"/>
      </div>
      <div class="chart">
        <p class="chart-title">Proceeds</p>
        <TimelineChart ref="revenueChart" :interval="timeframe" :labels="purchaseTypes" :endDate="endDate" :colors="purchaseColors"/>
      </div>
    </div>

    <div class="horizontal-container">
      <div class="chart">
        <p class="chart-title">Time of Day</p>
        <TimeOfDayChart ref="purchasesTODChart" :labels="[purchaseTypes[0], purchaseTypes[2], purchaseTypes[4]]" :colors="[purchaseColors[0], purchaseColors[2], purchaseColors[4]]"/>
      </div>
    </div>

    <div class="horizontal-container">
      <div class="chart">
        <p class="chart-title">Day of Week</p>
        <HistogramChart ref="purchasesDOWChart" :tags="[purchaseTypes[0], purchaseTypes[2], purchaseTypes[4]]" :labels="['Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun']"/>
      </div>
    </div>

    <div class="repeat-purchases-container">
        <h2>Users with More than One Purchase:</h2>
        <div>
          <input type="checkbox" id="renewals-checkbox" v-model="multiplePurchaseIncludeRenewals" />
          <label for="renewals-checkbox">Include renewals</label>
        </div>
        <ul>
          <template v-for="user in getMultiPurchaseUsers()" :key="user.userId">
            <h3>{{ user.userId }}</h3>
            <ul>
              <li v-for="purchase in user.purchases" :key="purchase.timestamp">
                <p>{{ formatDate(new Date(purchase.timestamp), 'hour') }}: {{ purchase.productId }} {{ purchase.isRenewal ? "(renewal)" : "" }}</p>
              </li>
            </ul>
          </template>
        </ul>
      </div>
  </div>

  <div>
    Invalid purchases
    {{invalidPurchases}}
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { PurchaseData, PurchaseDataRaw } from '../interfaces/PurchaseData'
import TimelineChart, { TimelineChartEvent } from '../components/TimelineChart.vue'
import TimeOfDayChart, { TimeOfDayChartEvent } from '../components/TimeOfDayChart.vue'
import MultipleChoiceToggle from '../components/MultipleChoiceToggle.vue'
import * as fb from '../firebase'
import { nextTick } from 'vue'
import { parseQuery, useRoute, useRouter } from 'vue-router'
import HistogramChart, { HistogramChartItem } from '@/components/HistogramChart.vue'

@Options({
  props: {
    dataFile: String
  },
  components: {
    TimelineChart,
    TimeOfDayChart,
    MultipleChoiceToggle,
    HistogramChart,
  },
})
export default class PurchasesView extends Vue {
  invalidPurchases: string[] = [
    '2024-04-10--Nv7facPvLyr_poMIhok', // duplicate yearly sub

    // '2024-04-28--Nw_YBFHhYagFH7tlQbL', // permanent premium
    // '2024-04-30--Nwhgsy35B-OZN5NkyGV', // permanent premium (same user) F1JD66pZeFPa9eiQWIrjEkvRFom2
    // '2024-05-24--NydNjebjOfG8C9KZW1F', // permanent premium (same user)

    // '2024-05-06--NxETGFcHjaWwAx_ziDP', // Transaction regisetered three times, last two with same transactionId
    // '2024-05-06--NxETGFjJvNIC_mRVw5x', // Same transaction as above, but user seems to have made two purchases so adding this back in
  ]

  purchaseColors = ['#002dbf', '#1a4fff', '#99004d', '#ff0080', '#00a69d']
  purchaseTypes = ['Monthly', 'Monthly Renewal', 'Yearly', 'Yearly Renewal', '10-day']
  platformLabels = ['All', 'Apple', 'Android']
  timeframeLabels = ['30 days', '1 year', 'All'] // TODO: Change to [30 day, 1 year, all] with [daily, weekly, monthly]
  date = ''

  timeframe: string = ''
  platforms: string[] = []
  startDate: Date = new Date()
  endDate: Date = new Date()
  isUtc: boolean = false
  multiplePurchaseIncludeRenewals: boolean = false

  dataFile!: string
  allPurchases: PurchaseData[] = []
  purchases: PurchaseData[] = []

  totalProceeds: number = 0.0
  subMonthCount = 0
  subYearCount = 0
  pass10Count = 0
  userPurchases: {
    [userId: string]: PurchaseData[]
  } = {}

  purchasesChart: TimelineChart | null = null
  revenueChart: TimelineChart | null = null
  purchasesTODChart: TimeOfDayChart | null = null
  purchasesDOWChart: HistogramChart | null = null

  unsubPurchases: (() => void) | null = null

  beforeMount() {
    const route = useRoute()
    const router = useRouter()

    if (route.query.date) {
      this.date = route.query.date as string
    } else {
      this.setDate(new Date())
      /*
      const query = { ...(route.query as { [key: string]: any }), date: this.date }
      router.push({ query })
      */
    }

  }
  
  async mounted() {
    this.purchasesChart = this.$refs.purchasesChart as TimelineChart
    this.revenueChart = this.$refs.revenueChart as TimelineChart
    this.purchasesTODChart = this.$refs.purchasesTODChart as TimeOfDayChart
    this.purchasesDOWChart = this.$refs.purchasesDOWChart as HistogramChart

    this.unsubPurchases = fb.observe('iap/purchases', (snapshot) => {
      this.update(snapshot.val())
    }, (error) => {
      console.log(error)
    })

    // await this.loadFileData()
  }

  beforeUnmount() {
    if (this.unsubPurchases) {
      this.unsubPurchases()
    }
  }

  async onTimeframeSelected(choice: number) {
    switch (choice) {
      case 0:
        this.timeframe = 'day'
        break
      case 1:
        this.timeframe = 'week'
        break
      case 2:
        this.timeframe = 'month'
        break
    }
    this.updateDateRange()
    await nextTick()
    this.analyzePurchases()
    this.updateCharts()
  }

  async onPlatformSelected(choice: number) {
    switch (choice) {
      case 0:
        this.platforms = ['apple', 'google']
        break
      case 1:
        this.platforms = ['apple']
        break
      case 2:
        this.platforms = ['google']
        break
    }
    this.analyzePurchases()
    this.updateCharts()
  }

  async dateDecr() {
    const date = new Date(this.date)
    date.setDate(date.getDate() - 1)
    this.setDate(date)
    this.updateDateRange()
    await nextTick()
    this.analyzePurchases()
    this.updateCharts()
  }

  async dateIncr() {
    const date = new Date(this.date)
    date.setDate(date.getDate() + 1)
    this.setDate(date)
    this.updateDateRange()
    await nextTick()
    this.analyzePurchases()
    this.updateCharts()
  }

  setDate(date: Date) {
    const year = date.getFullYear().toString()
    const month = ('0' + (date.getMonth() + 1)).slice(-2)
    const day = ('0' + date.getDate()).slice(-2)
    this.date = `${year}-${month}-${day}`
  }

  async loadFileData() {
    try {
      const response = await fetch(this.getDataFilePath())
      if (!response.ok) {
        throw new Error('Failed to load data')
      }
      const data = await response.text()

      const dataObject = JSON.parse(data)
      this.update(dataObject)
      
    } catch (error) {
      console.error(error)
      // Handle error here
    }
  }

  update(purchasesData: any) {
    this.allPurchases = []
    this.userPurchases = {}
    for (const purchaseId of Object.keys(purchasesData)) {
      if (this.invalidPurchases.includes(purchaseId)) {
        continue
      }
      
      const purchaseRaw = purchasesData[purchaseId] as PurchaseDataRaw

      let platformId: string = ''
      if (purchaseRaw.platform_data.transaction_id) {
        platformId = purchaseRaw.platform + '-' + purchaseRaw.platform_data.transaction_id
      } else if (purchaseRaw.platform_data.order_id) {
        platformId = purchaseRaw.platform + '-' + purchaseRaw.platform_data.order_id
      }

      if (this.allPurchases.findIndex((p) => { return p.platformId === platformId }) !== -1) {
        this.invalidPurchases.push(purchaseId)
        continue
      }
      
      const purchase: PurchaseData = {
        timestamp: purchaseRaw.timestamp,
        platform: purchaseRaw.platform,
        productId: purchaseRaw.product_id,
        proceeds: this.getProceedsEstimate(purchaseRaw.product_id),
        userId: purchaseRaw.user_id,
        isRenewal: false,
        platformId: platformId,
      }

      this.allPurchases.push(purchase)

      if (!this.userPurchases[purchase.userId]) {
        this.userPurchases[purchase.userId] = []
      }

      if (this.getProduct(purchase.productId).includes('sub')) {
        for (const userPurchase of this.userPurchases[purchase.userId]) {
          if (userPurchase.productId === purchase.productId) {
            purchase.isRenewal = true
          }
        }
      }

      this.userPurchases[purchase.userId].push(purchase)
    }

    this.analyzePurchases()
    this.updateCharts()
  }

  updateDateRange() {
    const endDate = new Date(this.date)
    endDate.setHours(23, 59, 59, 999)

    let startDate = new Date(this.date)
    startDate.setHours(0, 0, 0, 0)
    switch (this.timeframe) {
      case 'day':
        startDate.setDate(startDate.getDate() - 29)
        break
      case 'week':
        startDate.setDate(startDate.getDate() - 364)
        break
      case 'month':
        startDate = new Date('2024-01-01 00:00:00')
        break
    }
    this.startDate = startDate
    this.endDate = endDate
  }

  analyzePurchases() {
    this.purchases = []
    this.totalProceeds = 0.0
    this.subMonthCount = 0
    this.subYearCount = 0
    this.pass10Count = 0

    for (const purchase of this.allPurchases) {
      if (!this.platforms.includes(purchase.platform)) {
        continue
      }
      if (purchase.timestamp < this.startDate.getTime()) {
        continue
      }
      if (purchase.timestamp > this.endDate.getTime()) {
        continue
      }

      this.purchases.push(purchase)
    }

    for (const purchase of this.purchases) {
      this.totalProceeds += purchase.proceeds

      switch (this.getProduct(purchase.productId)) {
        case 'pass-10':
          this.pass10Count++
          break
        case 'sub-month':
          this.subMonthCount++
          break
        case 'sub-year':
          this.subYearCount++
          break
        default:
          break
      }
    }
  }

  updateCharts() {

    const purchasesChartData: TimelineChartEvent[] = []
    for (const purchase of this.purchases) {
      const value = 1
      // const value = this.getProceedsEstimate(purchase.productId)
      purchasesChartData.push({
        type: this.getTag(purchase),
        date: this.toDate(purchase.timestamp),
        value: value,
      })
    }
    this.purchasesChart?.setData(purchasesChartData)

    const revenueChartData: TimelineChartEvent[] = []
    for (const purchase of this.purchases) {
      const value = this.getProceedsEstimate(purchase.productId)
      revenueChartData.push({
        type: this.getTag(purchase),
        date: this.toDate(purchase.timestamp),
        value: value,
      })
    }
    this.revenueChart?.setData(revenueChartData)

    const purchasesTODChartData: TimeOfDayChartEvent[] = []
    for (const purchase of this.purchases) {
      const value = 1
      purchasesTODChartData.push({
        type: this.getTag(purchase),
        date: this.toDate(purchase.timestamp),
        value: value,
      })
    }
    this.purchasesTODChart?.setData(purchasesTODChartData)

    const dowChartData: HistogramChartItem[] = []
    for (const purchase of this.purchases) {
      const dow = this.toDate(purchase.timestamp).getDay()
      dowChartData.push({
        type: this.getTag(purchase),
        value: (7 + dow - 1) % 7,
      })
    }
    
    this.purchasesDOWChart?.setData(dowChartData)
  }

  toDate(timestamp: number) {
    if (this.isUtc) {
      return this.localToUTC(new Date(timestamp))
    }
    return new Date(timestamp)
  }

  localToUTC(date: Date) {
    const utcTime = date.getTime() + (date.getTimezoneOffset() * 60000)
    return new Date(utcTime)
  }

  getMultiPurchaseUsers() {
    const result: {
      userId: string,
      purchases: PurchaseData[]
    }[] = []

    for (const userId of Object.keys(this.userPurchases)) {
      const allPurchases = this.userPurchases[userId]
      const nonRenewals = this.userPurchases[userId].filter((p) => { return !p.isRenewal })

      const purchases = this.multiplePurchaseIncludeRenewals ? allPurchases : nonRenewals
      if (purchases.length > 1) {
        result.push({
          userId: userId,
          purchases: purchases.sort((p0, p1) => { return p1.timestamp - p0.timestamp }),
        })
      }
    }

    return result.sort((u0, u1) => { return u1.purchases[0].timestamp - u0.purchases[0].timestamp })
  }

  getDataFilePath(): string {
    // Assuming dataFile is relative to the public directory
    return process.env.BASE_URL + 'data/' + this.dataFile
  }

  getProduct(productId: string) {
    switch (productId) {
      case 'se.hippsomapp.gpsorienteringrun.iap.premium10days':
        return 'pass-10'
      case 'se.hippsomapp.gpsorienteringrun.sub.premiummonthly':
        return 'sub-month'
      case 'se.hippsomapp.gpsorienteringrun.sub.premiumyearly':
        return 'sub-year'
      case 'se.hippsomapp.gpsorientering.iap.premium10days':
        return 'pass-10'
      case 'se.hippsomapp.gpsorientering.sub.premium:gpso-sub-monthly':
        return 'sub-month'
      case 'se.hippsomapp.gpsorientering.sub.premium:gpso-sub-yearly':
        return 'sub-year'
      default:
        console.log(`Missing product: ${productId}`)
        return 'missing'
    }
  }

  getProceedsEstimate(productId: string): number {
    /*
    const platformTakeRate = 0.15
    const avgVAT = 0.2
    
    const proceedsRate = 1 - platformTakeRate - avgVAT
    */
    let proceedsRate = 0.86 // empirical (includes VAT, platform take and currency conversion)
    proceedsRate *= 0.97 // prefer low estimate

    switch (this.getProduct(productId)) {
      case 'pass-10':
        return 19 * proceedsRate
      case 'sub-month':
        return 39 * proceedsRate
      case 'sub-year':
        return 349 * proceedsRate
      default:
        console.log(`Missing proceeds estimate: ${productId}`)
        return 0.0
    }
  }

  getTag(purchase: PurchaseData): string {
    switch (this.getProduct(purchase.productId)) {
      case 'pass-10':
        return this.purchaseTypes[4]
      case 'sub-month':
        return purchase.isRenewal ? this.purchaseTypes[1] : this.purchaseTypes[0]
      case 'sub-year':
        return purchase.isRenewal ? this.purchaseTypes[3] : this.purchaseTypes[2]
      default:
        console.log(`Missing tag: ${purchase.productId}`)
        return 'missing'
    }
    
  }

  formatDate(date: Date, interval: string) {
    const year = date.getFullYear().toString().slice(-2)
    const month = ('0' + (date.getMonth() + 1)).slice(-2)
    const day = ('0' + date.getDate()).slice(-2)
    const hours = ('0' + date.getHours()).slice(-2)

    switch (interval) {
      case 'day':
        return `${year}-${month}-${day}`
      case 'hour':
        return `${year}-${month}-${day} ${hours}:00`
    }
    
    return `${year}-${month}-${day} ${hours}:00`
  }
}
</script>

<style scoped>
.purchases {
  padding-top: 10px;
}

.top-bar {
  display: flex;
  justify-content: flex-end;
  padding-left: 30px;
  padding-right: 30px;
}

.arrow-button {
  border: none;
  background: none;
  font-size: 16px; /* Adjust font size as needed */
  cursor: pointer;
  margin-top: -4px;
  color: white; /* Set the color to white */
}

.product-counts {
  margin-top: -5px;
  margin-bottom: 15px;
}

.product-count {
  display: inline;
  margin-left: 15px;
  font-size: larger;
}
.product-count-label {
  display: inline;
  margin-left: 6px;
  margin-right: 15px;
  font-size: small;
}

.platform-toggle {
  margin-top: 15px;
  justify-content: center;
}

.timeframe-toggle {
  margin-top: 15px;
  width: 350px;
  justify-content: center;
}

.vertical-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center */
  align-items: center; /* Horizontally center */
}

.repeat-purchases-container {
  margin-top: 100px;
}

.chart-title {
  margin-top: 20px;
  margin-bottom: 0px;
}

/* Desktop */
.desktop-view .platform-toggle {
  width: 500px;
}

.desktop-view .horizontal-container {
  display: flex;
  justify-content: center;
}

.desktop-view .chart {
  max-width: 600px;
  flex: 1;
  padding: 0px 40px;
}

/* Mobile */
.mobile-view .horizontal-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Horizontally center */
}

.mobile-view .chart {
  width: 95%;
  flex: 1;
  padding: 0px 00px;
}

.mobile-view .platform-toggle {
  width: 350px;
}

.mobile-view .repeat-purchases-container {
  margin-top: 100px;
  scale: .7;
}
</style>

<template>
  <div class="container">
    <div class="input-group">
      <label for="email">Email:</label>
      <input type="email" id="email" v-model="email">
    </div>

    <div class="input-group">
      <label for="password">Password:</label>
      <input type="password" id="password" v-model="password">
    </div>

    <button @click="signIn">Sign In</button>
  </div>

</template>

<script>
import * as fb from '../firebase'

export default {
  data() {
    return {
      email: '',
      password: '',
    }
  },
  methods: {
    async signIn() {
      try {
        await fb.signIn(this.email, this.password)
      } catch (error) {
        console.log(error)
      }
      
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
}

.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  margin-right: 200px;
}

.input-group label {
  text-align: end;
  margin-right: 10px;
  width: 200px; /* Adjust the width of labels for symmetry */
}

.input-group input {
  flex-grow: 1; /* Allow input field to grow and fill remaining space */
  width: 200px; /* Set the input width to 300 pixels */
  height: 25px;
  border-radius: 5px; /* Add border radius for rounded corners */
}

button {
  margin-top: 20px;
  width: 150px;
  height: 30px;
  background-color: var(--highlight-color);
  border: none; /* Remove default button border */
  border-radius: 5px; /* Add border radius for rounded corners */
  color: white; /* Set text color */
  font-size: 16px; /* Adjust font size */
  cursor: pointer; /* Change cursor on hover */
  transition: background-color 0.3s ease; /* Add transition effect for smoother hover */
}

button:hover {
  background-color: var(--highlight-color-dark);
}

</style>

<template>
  <div v-if="Object.keys(users).length > 0" class="users">
    <h2> Users </h2>
    <div class="center-align">
        <p>Guest: {{ guestCount }}</p>
        <p>Registered: {{ registeredCount }}</p>

        <div class="premium-section">
            <p>Premium:</p>
            <div class="premium-subcategories">
                <p>- permanent: {{ permanentPremiumCount }}</p>
                <p>- active: {{ activePremiumCount }}</p>
                <p>- expired: {{ expiredPremiumCount }}</p>
            </div>
        </div>

        <br>
        <br>
        <p>Paid android: {{ paidAndroidCount }}</p>
        <p>Difference since 3/5 2024: {{ paidAndroidCount + permanentPremiumCount -  2081}}</p>
    </div>

    <div class="vertical-container">
      <MultipleChoiceToggle class="usertype-toggle" :options="resourceLabels" :onSelected="onResourceGroupSelected"/>
      <p class="chart-title">Courses</p>
      <div class="chart">
        <HistogramChart ref="coursesChart" :tags="['guest', 'registered', 'premium', 'expired premium', 'permanent premium', 'paid android']"/>
      </div>
      <p class="chart-title">Results</p>
      <div>
        <div class="chart">
          <HistogramChart v-if="resourceGroup != 'downloaded'" ref="resultsChart" :tags="['guest', 'registered', 'premium', 'expired premium', 'permanent premium', 'paid android']"/>
          <p v-else> Not availible </p>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <br>
    <br>
    <br>
    <br>
    Loading...
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import * as fb from '../firebase'
import { UserData, UserDataRaw } from '../interfaces/UserData'
import HistogramChart, { HistogramChartItem } from '@/components/HistogramChart.vue'
import MultipleChoiceToggle from '../components/MultipleChoiceToggle.vue'
import { nextTick } from 'vue'

@Options({
  props: {
  
  },
  components: {
    HistogramChart,
    MultipleChoiceToggle,
  
  },
})
export default class UsersView extends Vue {
  excludedUsers: string[] = [
    'nA34YQrBRCOORLkUQLRs3xgTbls2', // samuel.arlig@protonmail.com
    'bcftfdlSlhM3fIDecV3MRTYpcEV2', // samuel.arlig@gmail.com
    'ExWPEJnGFNg2WKx9CDCRCI6CjlG2', // ulf.arlig@gmail.com
  ]

  resourceLabels = ['Downloaded', 'Private', 'Published']

  users: {
    [userId: string]: UserData
  } = {}

  invalidUsers: string[] = [
  /*
    'Hb7my4MFPHRNtDSCWeZXwPbRiFp2', // has created account but is still guest (paid android) (18 Mar 2021)
    'ay6ZkhtccSdHwvCBl657jOuCZr03', // has created account but is still guest (paid android) (23 May 2023)
    'm15XdCOA19Qg5a6UnFgFKdcU5D83', // has created account but is still guest (paid android) (5 Jan 2024)
    'RO25X4DSEhhSHaFGPgUtE5nBiGP2', // guest with published course (paid android) (19 Dec 2020)
    'gQUhoBGZPLM2VB4Cjzr1aiasRzk2', // guest with published course (paid android) (22 Oct 2021)
    'i6Fv9lMtcPeRXl85m65XffbLJS63', // guest with published course (paid android) (13 Feb 2021)
    'r4GHDq1U6vUgMhFy7n8YNWSnEAS2', // guest with published course (paid android) (4 Jan 2021)
  */
  ]

  guestCount: number = 0
  registeredCount: number = 0
  expiredPremiumCount: number = 0
  activePremiumCount: number = 0
  permanentPremiumCount: number = 0
  paidAndroidCount: number = 0

  coursesChart: HistogramChart | null = null
  resultsChart: HistogramChart | null = null
  resourceGroup: 'downloaded' | 'private' | 'published' = 'downloaded'

  updateChartsCounter: number = 0

  unsubUsers: (() => void) | null = null
  
  async mounted() {

    this.coursesChart = this.$refs.coursesChart as HistogramChart
    this.resultsChart = this.$refs.resultsChart as HistogramChart

    this.unsubUsers = fb.observeUsers((snapshot) => {
      if (snapshot.exists()) {
        const userId = snapshot.key!
        const rawData = snapshot.val() as UserDataRaw
        
        this.updateUser(userId, rawData)
      } else {
        console.log('removed')
      }
    }, (error) => {
      console.log(error)
    })
  }

  beforeUnmount() {
    if (this.unsubUsers) {
      this.unsubUsers()
    }
  }

  async onResourceGroupSelected(choice: number) {
    switch (choice) {
      case 0:
        this.resourceGroup = 'downloaded'
        break
      case 1:
        this.resourceGroup = 'private'
        break
      case 2:
        this.resourceGroup = 'published'
        break
    }
    
    this.updateCharts()
  }

  tryUpdateCharts() {
    this.updateChartsCounter += 1
    const count = this.updateChartsCounter

    setTimeout(() => {
      if (this.updateChartsCounter === count) {
        this.updateCharts()
      }
    }, 10000)
  }

  async updateCharts() {
    const coursesChartData: HistogramChartItem[] = []
    const resultsChartData: HistogramChartItem[] = []
    
    const maxCount = 10
    for (const user of Object.values(this.users)) {

      // Courses
      let coursesCount: number
      switch (this.resourceGroup) {
        case 'downloaded':
          coursesCount = Math.min(user.downloadedCourses, maxCount)
          break
        case 'private':
          coursesCount = Math.min(user.privateCourses, maxCount)
          break
        case 'published':
          coursesCount = Math.min(user.publishedCourses, maxCount)
          break
      }

      // Results
      let resultsCount: number
      switch (this.resourceGroup) {
        case 'downloaded':
          resultsCount = 0
          break
        case 'private':
          resultsCount = Math.min(user.privateResults, maxCount)
          break
        case 'published':
          resultsCount = Math.min(user.publishedResults, maxCount)
          break
      }

      // Label
      let label: string = user.accountType
      if (user.accountType === 'premium') {
        if (!user.premiumExpiryTime) {
          label = 'permanent premium'
        } else if (user.premiumExpiryTime < user.timestamp) {
          label = 'expired premium'
        }
      }
      if (user.accountType === 'registered' && user.paidAndroid) {
        label = 'paid android'
      }

      // Append
      if (coursesCount > 0) {
        coursesChartData.push({
          type: label,
          value: coursesCount,
        })
      }

      if (resultsCount > 0) {
        resultsChartData.push({
          type: label,
          value: resultsCount,
        })
      }
    }

    await nextTick()
    
    this.coursesChart?.setData(coursesChartData)
    this.resultsChart?.setData(resultsChartData)
  }

  updateUser(userId: string, rawData: UserDataRaw) {
    if (this.users[userId]) {
      this.subUserAnalytics(userId)
    }

    if (this.excludedUsers.includes(userId) || this.invalidUsers.includes(userId)) {
      return
    }

    if (!rawData.account) {
      this.invalidUsers.push(userId)
      return
    }

    let downloadedCourses = 0
    if (rawData.resources?.downloaded_courses) {
      const courseIds = Object.keys(rawData.resources.downloaded_courses)
      downloadedCourses = courseIds.length
      if (courseIds.includes('szheh9ic')) {
        downloadedCourses--
      }
    }

    let publishedCourses = 0
    if (rawData.resources?.published_courses) {
      const courseIds = Object.keys(rawData.resources.published_courses)
      publishedCourses = courseIds.length
    }

    let privateCourses = 0
    if (rawData.resources?.private_courses) {
      const courseIds = Object.keys(rawData.resources.private_courses)
      privateCourses = courseIds.length
    }

    let publishedResults = 0
    if (rawData.resources?.published_results) {
      const courseIds = Object.keys(rawData.resources.published_results)
      publishedResults = courseIds.length
    }

    let privateResults = 0
    if (rawData.resources?.private_results) {
      const courseIds = Object.keys(rawData.resources.private_results)
      privateResults = courseIds.length
    }

    const userData: UserData = {
      timestamp: Date.now(),
      id: userId,
      accountType: rawData.account.type,
      premiumExpiryTime: rawData.account.expire_time,
      paidAndroid: rawData.account.paid_android,
      downloadedCourses: downloadedCourses,
      publishedCourses: publishedCourses,
      privateCourses: privateCourses,
      privateResults: privateResults,
      publishedResults: publishedResults,
    }

    this.users[userId] = userData
    this.addUserAnalytics(userId)
    // this.tryUpdateCharts()

    this.validateUser(userData)
  }

  removeUser(userId: string) {
    this.subUserAnalytics(userId)
    delete this.users.userId
  }

  addUserAnalytics(userId: string) {
    const userData = this.users[userId]

    if (userData.accountType === 'guest') {
      this.guestCount++
    } else if (userData.accountType === 'registered') {
      this.registeredCount++
    } else if (userData.accountType === 'premium') {
      if (userData.premiumExpiryTime) {
        if (userData.premiumExpiryTime > userData.timestamp) {
          this.activePremiumCount++
        } else {
          this.expiredPremiumCount++
        }
      } else {
        this.permanentPremiumCount++
      }
    }

    if (userData.paidAndroid) {
      this.paidAndroidCount++
    }
  }

  subUserAnalytics(userId: string) {
    const userData = this.users[userId]
    if (userData.accountType === 'guest') {
      this.guestCount--
    } else if (userData.accountType === 'registered') {
      this.registeredCount--
    } else if (userData.accountType === 'premium') {
      if (userData.premiumExpiryTime) {
        if (userData.premiumExpiryTime > userData.timestamp) {
          this.activePremiumCount--
        } else {
          this.expiredPremiumCount--
        }
      } else {
        this.permanentPremiumCount--
      }
    }

    if (userData.paidAndroid) {
      this.paidAndroidCount--
    }
  }

  validateUser(user: UserData) {
    if (user.accountType === 'guest') {
      if (user.publishedCourses > 0) {
        console.log('guest with published course: ' + user.id)
      }
    }

    if (user.accountType !== 'premium' && !user.paidAndroid) {
      if (user.privateCourses > 1) {
        console.log('non-premium with more than one private course: ' + user.id)
      }
    }
  }

}
</script>

<style scoped>
.users {
  padding-top: 30px;
  padding-bottom: 50px;
}
.center-align {
    text-align: center;
}
.premium-section {
    display: inline-block;
    text-align: left;
}
.premium-subcategories {
    margin-left: 20px;
}

.vertical-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center */
  align-items: center; /* Horizontally center */
}

.usertype-toggle {
  margin-top: 15px;
  justify-content: center;
  width: 350px;
}

.chart {
  height: 400px;
}

.chart-title {
  margin-top: 20px;
  margin-bottom: 0px;
}

/* Desktop */

.desktop-view .chart {
  width: 800px;
  flex: 1;
  padding: 0px 40px;
}

/* Mobile */
.mobile-view .chart {
  width: 95%;
  flex: 1;
  padding: 0px 00px;
}
</style>

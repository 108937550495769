import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61f5eca8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "signed-in"
}
const _hoisted_2 = { class: "top-bar" }
const _hoisted_3 = { class: "left" }
const _hoisted_4 = { class: "tabs" }
const _hoisted_5 = { class: "right" }
const _hoisted_6 = {
  key: 1,
  class: "signed-out"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_SignInView = _resolveComponent("SignInView")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'desktop-view': !_ctx.isMobile, 'mobile-view': _ctx.isMobile })
  }, [
    (_ctx.isSignedIn)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_router_link, {
                  class: _normalizeClass(["tab", { 'selected-tab': _ctx.$route.name === 'purchases' }]),
                  to: "/purchases"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Purchases")
                  ]),
                  _: 1
                }, 8, ["class"]),
                _createVNode(_component_router_link, {
                  class: _normalizeClass(["tab", { 'selected-tab': _ctx.$route.name === 'users' }]),
                  to: "/users"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Users")
                  ]),
                  _: 1
                }, 8, ["class"])
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.signOut && _ctx.signOut(...args))),
                class: "sign-out-btn"
              }, "Sign Out")
            ])
          ]),
          _createVNode(_component_router_view, { "is-mobile": _ctx.isMobile }, null, 8, ["is-mobile"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_SignInView)
        ]))
  ], 2))
}
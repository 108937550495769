import { createRouter, createWebHistory } from 'vue-router'
import PurchasesView from './views/PurchasesView.vue'
import UsersView from './views/UsersView.vue'

const routes = [
  {
    path: '/purchases',
    name: 'purchases',
    component: PurchasesView,
  },
  {
    path: '/users',
    name: 'users',
    component: UsersView,
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/purchases',
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    window.scrollTo(0, 0)
  }
})

export default router

<template>
  <div v-bind:class="{ 'desktop-view': !isMobile, 'mobile-view': isMobile }" >
    <div v-if="isSignedIn" class="signed-in">
      <div class="top-bar">
        <div class="left">
          <div class="tabs">
            <router-link class="tab" :class="{ 'selected-tab': $route.name === 'purchases' }" to="/purchases">Purchases</router-link>
            <router-link class="tab" :class="{ 'selected-tab': $route.name === 'users' }" to="/users">Users</router-link>
          </div>
        </div>
        <div class="right">
          <button @click="signOut" class="sign-out-btn">Sign Out</button>
        </div>
      </div>
      
      <router-view :is-mobile="isMobile"/>
    </div>
    <div v-else class="signed-out">
      <SignInView />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import PurchasesView from './views/PurchasesView.vue'
import SignInView from './views/SignInView.vue'
import * as fb from './firebase'

@Options({
  components: {
    PurchasesView,
    SignInView,
  }
})
export default class App extends Vue {

  isMobile = false
  isSignedIn = false

  created() {
    this.isMobile = window.innerWidth <= 768
    fb.setAuthStateListener(this.onAuthStateChanged)
  }

  signOut() {
    fb.signOut()
  }

  async onAuthStateChanged(signedIn: boolean) {
    if (signedIn) {
      // Check if admin
      const userId = fb.currentUser()?.uid
      try {
        await fb.get('internal/admins/' + userId)
        this.isSignedIn = true
      } catch {
        console.log('permission denied')
        this.signOut()
      }
    } else {
      this.isSignedIn = false
    }
  }
}
</script>

<style>
:root {
  --highlight-color: #FF8300;
  --highlight-color-dark: rgb(155, 85, 0);
  --primary-color: #a7a83b;
  --secondary-color: #012622;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: aliceblue;
}

body {
  background-color: var(--secondary-color);
  margin: 0px;
}
</style>

<style scoped>

.top-bar {
  display: flex;
  justify-content: space-between;
  padding: 0px 14px;
  position: sticky;
  height: 60px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: #00000080;
  backdrop-filter: blur(5px);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
}

.top-bar .left {
  display: flex;
  align-items: center;
}

.top-bar .right {
  display: flex;
  align-items: center;
}

/* Tabs */
.tabs {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.tab {
  padding: 0px 15px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  cursor: pointer;
  text-decoration: none;
  color: white;
}

.tab:hover,
.selected-tab {
  color: var(--primary-color);
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-decoration-color: var(--primary-color);
}

/* ---- */
.signed-in {
  position: relative;
}

.signed-out {
  padding-top: 200px; /* Adjust this value as needed */
}

.sign-out-btn {
  height: 28px;
  padding: 5px 10px;
  background-color:  var(--highlight-color);
  border: none; /* Remove default button border */
  border-radius: 5px; /* Add border radius for rounded corners */
  color: white;
  cursor: pointer; /* Change cursor on hover */
  transition: background-color 0.3s ease; /* Add transition effect for smoother hover */
}

.sign-out-btn:hover {
  background-color:  var(--highlight-color-dark);
}

/* Desktop */
.desktop-view .top-bar {
  height: 60px;
}

/* Mobile */
.mobile-view .top-bar {
  height: 50px;
}

</style>

<template>
  <div class="multiple-choice-toggle">
    <div class="toggle-option"
         v-for="(option, index) in options"
         :key="index"
         :class="{ 'active': selectedIndex === index }"
         @click="this.selectOption(index)">
      {{ option }}
    </div>
  </div>
</template>

<script>

export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    onSelected: {
    }
  },
  data() {
    return {
      selectedIndex: 0
    }
  },
  created() {
    this.selectOption(0)
  },
  methods: {
    selectOption(index) {
      this.selectedIndex = index
      this.onSelected(index)
    }
  },
}
</script>

<style scoped>
  .multiple-choice-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ccc3;
    border-radius: 20px;
    height: 40px;
    padding: 5px;
    cursor: pointer;
  }

  .toggle-option {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
    border-radius: 20px;
    transition: all 0.3s ease;
  }

  .toggle-option.active {
    color: #fff;
    background-color: var(--highlight-color);
  }
</style>

<template>
  <vue3-chart-js ref="chart" v-bind="{ ...barChart }" />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'
import { ChartOptions } from '../interfaces/ChartOptions'

export interface TimeOfDayChartEvent {
  date: Date,
  type: string,
  value: number,
}

interface EventGroup {
  date: Date,
  events: {
    [type: string]: number
  }
}

interface Props {
  colors: string[];
  labels: string[];
  interval: string;
}

@Options({
  props: {
    labels: Array,
    colors: Array,
    interval: String
  },
  components: {
    Vue3ChartJs,
  },
})
export default class TimeOfDayChart extends Vue {
  
  barChart: ChartOptions = {
    type: 'bar',
    options: {
      responsive: true,
      plugins: {
        legend: {
          labels: {
            color: 'aliceblue'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: 'aliceblue'
          },
          grid: {
            color: '#FFF0'
          }
        },
        y: {
          ticks: {
            color: 'aliceblue',
            precision: 0,
          },
          grid: {
            color: '#FFF3'
          }
        }
      }
    },
    data: {
      labels: [],
      datasets: [],
    },
  }

  defaultColors = ['#002dbf', '#99004d', '#00a69d', '#cc5c00', '#5f009e', '#ad9f00', '#db6393', '#006344', '#bf0000', '#00b000']
  
  events: TimeOfDayChartEvent[] = []

  setData(events: TimeOfDayChartEvent[]) {
    let interval = this.props().interval
    if (!interval) {
      interval = 'hour'
    }

    let colors = this.props().colors
    if (!colors) {
      colors = this.defaultColors
    }

    this.events = events

    const chartData = this.groupData(this.events, interval)

    let labels = this.props().labels
    if (!labels) {
      labels = [...new Set(this.events.map(item => item.type))]
    }

    this.barChart.data.labels = chartData.map(data => this.formatDate(data.date, interval))
    this.barChart.data.datasets = labels.map((type, i) => ({
      label: type,
      data: chartData.map(data => data.events[type] || 0),
      backgroundColor: colors[i],
      borderColor: '#000000',
      borderWidth: 0.2,
      stack: 'Stack 1',
    }))

    const chart = this.$refs.chart as any
    chart.update()
  }

  groupData(data: TimeOfDayChartEvent[], interval: string) {
    let intervalMillis = 1000 * 60
    if (interval === 'hour') {
      intervalMillis = 1000 * 60 * 60
    }

    const grouped: EventGroup[] = []

    for (const curr of data) {
      const timeOfDayMillis = 1000 * (curr.date.getHours() * 60 * 60 + curr.date.getMinutes() * 60)
      
      const g = Math.floor(timeOfDayMillis / intervalMillis)
      if (g < 0) {
        continue
      }
      const type = curr.type
      if (!grouped[g]) {
        grouped[g] = { date: curr.date, events: {} }
      }
      if (!grouped[g].events[type]) {
        grouped[g].events[type] = 0
      }
      grouped[g].events[type] += curr.value
    }

    return grouped
  }

  pruneDate(date: Date, interval: string) {
    switch (interval) {
      case 'day':
        return new Date(date.setHours(0, 0, 0, 0))
      case 'hour':
        return new Date(date.setMinutes(0, 0, 0))
    }
    return new Date(date.setSeconds(0, 0))
  }

  formatDate(date: Date, interval: string) {
    const year = date.getFullYear().toString().slice(-2)
    const month = ('0' + (date.getMonth() + 1)).slice(-2)
    const day = ('0' + date.getDate()).slice(-2)
    const hours = ('0' + date.getHours()).slice(-2)

    switch (interval) {
      case 'hour':
        return `${hours}:00`
    }
    
    return `${year}-${month}-${day} ${hours}:00`
  }

  props() {
    return this.$props as Props
  }
}
</script>

<style scoped>

</style>

<template>
  <vue3-chart-js ref="chart" v-bind="{ ...barChart }" />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'
import { ChartOptions } from '../interfaces/ChartOptions'

export interface TimelineChartEvent {
  date: Date,
  type: string,
  value: number,
}

interface EventGroup {
  date: Date,
  events: {
    [type: string]: number
  }
}

interface Props {
  endDate: Date;
  colors: string[];
  labels: string[];
  interval: string;
}

@Options({
  props: {
    labels: Array,
    colors: Array,
    interval: String,
    endDate: Date,
  },
  components: {
    Vue3ChartJs,
  },
})
export default class TimelineChart extends Vue {
  
  barChart: ChartOptions = {
    type: 'bar',
    options: {
      responsive: true,
      plugins: {
        legend: {
          labels: {
            color: 'aliceblue'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: 'aliceblue'
          },
          grid: {
            color: '#FFF0'
          }
        },
        y: {
          ticks: {
            color: 'aliceblue',
            precision: 0,
          },
          grid: {
            color: '#FFF3'
          }
        }
      }
    },
    data: {
      labels: [],
      datasets: [],
    },
  }

  defaultColors = ['#002dbf', '#99004d', '#00a69d', '#cc5c00', '#5f009e', '#ad9f00', '#db6393', '#006344', '#bf0000', '#00b000']
  
  events: TimelineChartEvent[] = []

  setData(events: TimelineChartEvent[]) {
    if (events.length === 0) {
      this.clear()
      return
    }

    let interval = this.props().interval
    if (!interval) {
      interval = 'day'
    }

    let colors = this.props().colors
    if (!colors) {
      colors = this.defaultColors
    }

    this.events = events

    const chartData = this.groupData(this.events, interval)

    let labels = this.props().labels
    if (!labels) {
      labels = [...new Set(this.events.map(item => item.type))]
    }

    this.barChart.data.labels = chartData.map(data => this.formatDate(data.date, interval))
    this.barChart.data.datasets = labels.map((type, i) => ({
      label: type,
      data: chartData.map(data => data.events[type] || 0),
      backgroundColor: colors[i],
      borderColor: '#000000',
      borderWidth: 0.2,
      stack: 'Stack 1',
    }))

    const chart = this.$refs.chart as any
    chart.update()
  }

  clear() {
    this.barChart.data.labels = []
    this.barChart.data.datasets = []
    const chart = this.$refs.chart as any
    chart.update()
  }

  groupData(data: TimelineChartEvent[], interval: string) {
    const grouped: EventGroup[] = []

    grouped[0] = { date: this.prunedDate(data[0].date, interval), events: {} }

    let g = 0
    for (const curr of data) {
      const date = this.prunedDate(curr.date, interval)

      while (date.getTime() > grouped[g].date.getTime()) {
        const lastDate = grouped[g].date
        g++
        grouped[g] = { date: this.addingInterval(lastDate, interval), events: {} }
      }

      if (!grouped[g].events[curr.type]) {
        grouped[g].events[curr.type] = 0
      }
      grouped[g].events[curr.type] += curr.value

    }

    const endDate = this.props().endDate ?? new Date()
    const now = this.prunedDate(endDate, interval)
    while (now.getTime() > grouped[g].date.getTime()) {
      g++
      grouped[g] = { date: this.addingInterval(grouped[g - 1].date, interval), events: {} }
    }

    return grouped
  }

  prunedDate(date: Date, interval: string) {
    const d = new Date(date)
    const day = (date.getDay() + 6) % 7
    
    switch (interval) {
      case 'month':
        d.setHours(0, 0, 0, 0)
        return new Date(d.setDate(1))
      case 'week':
        return new Date(d.setHours(0, 0, 0, 0) - day * 24 * 60 * 60 * 1000)
      case 'day':
        return new Date(d.setHours(0, 0, 0, 0))
      case 'hour':
        return new Date(d.setMinutes(0, 0, 0))
    }
    return new Date(d.setSeconds(0, 0))
  }

  addingInterval(date: Date, interval: string) {
    const d = new Date(date)
    
    if (interval === 'month') {
      d.setMonth(d.getMonth() + 1)
    } else if (interval === 'week') {
      d.setDate(d.getDate() + 7)
    } else if (interval === 'day') {
      d.setDate(d.getDate() + 1)
    } else if (interval === 'hour') {
      d.setHours(d.getHours() + 1)
    }
    
    return d
  }

  formatDate(date: Date, interval: string) {
    const year = date.getFullYear().toString().slice(-2)
    const month = ('0' + (date.getMonth() + 1)).slice(-2)
    const day = ('0' + date.getDate()).slice(-2)
    const hours = ('0' + date.getHours()).slice(-2)

    const monthName = date.toLocaleString('default', { month: 'short' })

    switch (interval) {
      case 'month':
        return `${monthName} ${year}`
      case 'week':
        return `Mon ${year}-${month}-${day}`
      case 'day':
        return `${year}-${month}-${day}`
      case 'hour':
        return `${year}-${month}-${day} ${hours}:00`
    }
    
    return `${year}-${month}-${day} ${hours}:00`
  }

  props() {
    return this.$props as Props
  }
}
</script>

<style scoped>

</style>

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'

// import admin from 'firebase-admin'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import * as fbAuth from 'firebase/auth'
import * as fbDatabase from 'firebase/database'

// Your web app's Firebase configuration
const firebaseConfigDev = {
  apiKey: 'AIzaSyA_dyEB51MiImwaFZm0KWHGzV9zJ-MCFjU',
  authDomain: 'gpsodev-c69f3.firebaseapp.com',
  projectId: 'gpsodev-c69f3',
  storageBucket: 'gpsodev-c69f3.appspot.com',
  messagingSenderId: '452599648116',
  appId: '1:452599648116:web:d534e921d72dcf61052621',
  measurementId: 'G-RSJ6CV1J7H',

  databaseURL: 'https://gpsodev-c69f3-default-rtdb.europe-west1.firebasedatabase.app',
}
const firebaseConfig = {
  apiKey: 'AIzaSyA19PIfBNzaUP1KvALnuesz4n7a2eWeEdo',
  authDomain: 'gpsorienteering-db7f5.firebaseapp.com',
  databaseURL: 'https://gpsorienteering-db7f5-9cde4.europe-west1.firebasedatabase.app/',
  projectId: 'gpsorienteering-db7f5',
  storageBucket: 'gpsorienteering-db7f5.appspot.com',
  messagingSenderId: '90522584091',
  appId: '1:90522584091:web:bce1f4fd338055b4d064be'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

const auth = fbAuth.getAuth(app)

export function isSignedIn() {
  console.log(auth.currentUser)
  return auth.currentUser != null
}
export function currentUser() {
  return auth.currentUser
}

export function signOut() {
  return fbAuth.signOut(auth)
}
export function signIn(email: string, password: string) {
  return fbAuth.signInWithEmailAndPassword(auth, email, password)
}
export function setAuthStateListener(listener: (signedIn: boolean) => void) {
  fbAuth.onAuthStateChanged(auth, (user) => {
    if (user) {
      listener(true)
    } else {
      listener(false)
    }
  })
}

export const db = fbDatabase.getDatabase(app)

export function observe(path: string, onValue: (snapshot: fbDatabase.DataSnapshot) => void, onError?: (error: Error) => void) {
  const purchasesRef = fbDatabase.ref(db, path)
  return fbDatabase.onValue(purchasesRef, onValue, onError)
}

export async function get(path: string) {
  const purchasesRef = fbDatabase.ref(db, path)
  return fbDatabase.get(purchasesRef)
}

export function observeUsers(onValue: (snapshot: fbDatabase.DataSnapshot) => void, onError?: (error: Error) => void) {
  const path = 'users'

  const purchasesRef = fbDatabase.ref(db, path)
  // const query = fbDatabase.query(purchasesRef, fbDatabase.limitToFirst(500))
  // const unsubAdded = fbDatabase.onChildAdded(query, onValue, onError)
  const unsubAdded = fbDatabase.onChildAdded(purchasesRef, onValue, onError)
  const unsubChanged = fbDatabase.onChildChanged(purchasesRef, onValue, onError)
  const unsubRemoved = fbDatabase.onChildRemoved(purchasesRef, onValue, onError)

  const unsubAll: fbDatabase.Unsubscribe = () => {
    unsubAdded()
    unsubChanged()
    unsubRemoved()
  }

  return unsubAll
}
